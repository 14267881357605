@media print {
  /* Estilos de impressão aqui */
  @page {
    size: landscape;
  }
  #paper-break { 
    page-break-inside: avoid;
  }
  body {
    margin: 0;
    padding: 0;
    zoom: 0.45;
    scale: 1;
  }
  #nao-imprimir {
    display: none;
  }
  #imprimir {
    display: flex !important;
  }
  #apresentar {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

#apresentar {
  display: none;
}